let map;
const categories = {};
const markersRef = [];

const mapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  }, {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  }, {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  }, {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  }, {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  }, {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  }, {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  }, {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  }, {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  }, {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  }, {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  }, {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  }, {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  }, {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  }, {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  }, {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  }, {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  }, {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

// obchody
categories[1] = {
  0: {
    lat: 48.1441667,
    lng: 17.1508332,
  },
  1: {
    lat: 48.1445703,
    lng: 17.1285485,
  },
  2: {
    lat: 48.1453123,
    lng: 17.1289931,
  },
  3: {
    lat: 48.1404868,
    lng: 17.1241542,
  },
  4: {
    lat: 48.1422016,
    lng: 17.1271719,
  },
  5: {
    lat: 48.1403215,
    lng: 17.121313,
  },
  6: {
    lat: 48.1435108,
    lng: 17.121019,
  },
  7: {
    lat: 48.1460841,
    lng: 17.1197722,
  },
  8: {
    lat: 48.1456555,
    lng: 17.1191378,
  },
  9: {
    lat: 48.1479087,
    lng: 17.1250609,
  },
  10: {
    lat: 48.1491716,
    lng: 17.1334747,
  },
  11: {
    lat: 48.1433778,
    lng: 17.1504552,
  },
  12: {
    lat: 48.1450939,
    lng: 17.1391419,
  },
  13: {
    lat: 48.1433076,
    lng: 17.1412695,
  },
  14: {
    lat: 48.1477133,
    lng: 17.1416126,
  },
  15: {
    lat: 48.1496635,
    lng: 17.1348165,
  },
  16: {
    lat: 48.1491716,
    lng: 17.1334747,
  },
  17: {
    lat: 48.1485,
    lng: 17.1449,
  },
};

// banky a bankomaty
categories[2] = {
  0: {
    lat: 48.1442365,
    lng: 17.1372966,
  },
  1: {
    lat: 48.1447482,
    lng: 17.1430487,
  },
  2: {
    lat: 48.1471539,
    lng: 17.142701,
  },
  3: {
    lat: 48.1467214,
    lng: 17.1500519,
  },
  4: {
    lat: 48.1482379,
    lng: 17.1495045,
  },
  5: {
    lat: 48.1445643,
    lng: 17.1285303,
  },
  6: {
    lat: 48.1453123,
    lng: 17.1289931,
  },
  7: {
    lat: 48.1482379,
    lng: 17.1495045,
  },
  8: {
    lat: 48.145837,
    lng: 17.126627,
  },
  9: {
    lat: 48.1461452,
    lng: 17.126096,
  },
  10: {
    lat: 48.1520234,
    lng: 17.1354756,
  },
  11: {
    lat: 48.1407113,
    lng: 17.1213087,
  },

};

// skoly a skolky
categories[3] = {
  0: {
    lat: 48.1459785,
    lng: 17.133854,
  },
  1: {
    lat: 48.146177,
    lng: 17.1331182,
  },
  2: {
    lat: 48.1452185,
    lng: 17.1213748,
  },
  3: {
    lat: 48.1463979,
    lng: 17.131586,
  },
  4: {
    lat: 48.149965,
    lng: 17.1441909,
  },
  5: {
    lat: 48.1502587,
    lng: 17.1434664,
  },
  6: {
    lat: 48.1508448,
    lng: 17.1437118,
  },
  7: {
    lat: 48.15068,
    lng: 17.1467259,
  },
  8: {
    lat: 48.1517509,
    lng: 17.1463617,
  },
  9: {
    lat: 48.1519969,
    lng: 17.1549291,
  },
  10: {
    lat: 48.1522587,
    lng: 17.1569077,
  },
  11: {
    lat: 48.1411918,
    lng: 17.1159646,
  },
  12: {
    lat: 48.1463026,
    lng: 17.1260369,
  },
  13: {
    lat: 48.1445263,
    lng: 17.1200579,
  },
  14: {
    lat: 48.1467103,
    lng: 17.1205348,
  },
  15: {
    lat: 48.1464428,
    lng: 17.1175219,
  },
  16: {
    lat: 48.1499367,
    lng: 17.1302589,
  },
  17: {
    lat: 48.1482385,
    lng: 17.1382152,
  },
  18: {
    lat: 48.1496194,
    lng: 17.1437726,
  },
  19: {
    lat: 48.1502912,
    lng: 17.1408764,
  },
  20: {
    lat: 48.1506098,
    lng: 17.1362985,
  },
  21: {
    lat: 48.1505242,
    lng: 17.1498111,
  },

};

// urady
categories[4] = {
  0: {
    lat: 48.1509384,
    lng: 17.1430847,
  },
  1: {
    lat: 48.1443842,
    lng: 17.1229411,
  },
  2: {
    lat: 48.1471485,
    lng: 17.1141486,
  },
  3: {
    lat: 48.1457005,
    lng: 17.1107632,
  },
  4: {
    lat: 48.140279,
    lng: 17.1112359,
  },
  5: {
    lat: 48.1496163,
    lng: 17.1642447,
  },
  6: {
    lat: 48.1471797,
    lng: 17.1711427,
  },
  7: {
    lat: 48.1455259,
    lng: 17.1426307,
  },
  8: {
    lat: 48.1401282,
    lng: 17.1204731,
  },
  9: {
    lat: 48.1406988,
    lng: 17.1275639,
  },
};

// mhd
categories[5] = {
  0: {
    lat: 48.1495612,
    lng: 17.1495932,
  },
  1: {
    lat: 48.1472299,
    lng: 17.1416491,
  },
  2: {
    lat: 48.1462635,
    lng: 17.1373683,
  },
  3: {
    lat: 48.1453705,
    lng: 17.1331005,
  },
  4: {
    lat: 48.1423683,
    lng: 17.1290264,
  },
  5: {
    lat: 48.1408997,
    lng: 17.1451206,
  },
  6: {
    lat: 48.145293,
    lng: 17.1292586,
  },
  7: {
    lat: 48.1496494,
    lng: 17.134434,
  },
  8: {
    lat: 48.153579,
    lng: 17.1359146,
  },
  9: {
    lat: 48.1421547,
    lng: 17.1229382,
  },
  10: {
    lat: 48.1417428,
    lng: 17.1190263,
  },
  11: {
    lat: 48.153553,
    lng: 17.1246872,
  },
  12: {
    lat: 48.147511,
    lng: 17.125911,
  },
};

// kaviarne a restauracie
categories[6] = {
  0: {
    lat: 48.143026,
    lng: 17.1344013,
  },
  1: {
    lat: 48.1475568,
    lng: 17.1473684,
  },
  2: {
    lat: 48.1489171,
    lng: 17.144419,
  },
  3: {
    lat: 48.1517251,
    lng: 17.1418925,
  },
  4: {
    lat: 48.150212,
    lng: 17.1339417,
  },
  5: {
    lat: 48.1399469,
    lng: 17.1210577,
  },
  6: {
    lat: 48.1463084,
    lng: 17.121162,
  },
  7: {
    lat: 48.1498318,
    lng: 17.1222139,
  },
  8: {
    lat: 48.1472589,
    lng: 17.118046,
  },
  9: {
    lat: 48.1445851,
    lng: 17.1181818,
  },
  10: {
    lat: 48.1398481,
    lng: 17.1226587,
  },
  11: {
    lat: 48.1519186,
    lng: 17.1311285,
  },
  12: {
    lat: 48.145933,
    lng: 17.138315,
  },
  13: {
    lat: 48.1506392,
    lng: 17.13285,
  },
  14: {
    lat: 48.1468759,
    lng: 17.1394591,
  },
  15: {
    lat: 48.1400642,
    lng: 17.1538878,
  },
  16: {
    lat: 48.145938,
    lng: 17.1594595,
  },
  17: {
    lat: 48.149113,
    lng: 17.1537398,
  },
  18: {
    lat: 48.1454241,
    lng: 17.113735,
  },
  19: {
    lat: 48.1413586,
    lng: 17.1168805,
  },
  20: {
    lat: 48.1464324,
    lng: 17.1237266,
  },
  21: {
    lat: 48.1526209,
    lng: 17.1419456,
  },
  22: {
    lat: 48.1407146,
    lng: 17.1095765,
  },
  23: {
    lat: 48.1463436,
    lng: 17.1603725,
  },
  24: {
    lat: 48.1473686,
    lng: 17.1443061,
  },
};

// nemocnice a lekarne
categories[7] = {
  0: {
    lat: 48.1461941,
    lng: 17.1173437,
  },
  1: {
    lat: 48.1408531,
    lng: 17.1185361,
  },
  2: {
    lat: 48.1458334,
    lng: 17.111691,
  },
  3: {
    lat: 48.1507666,
    lng: 17.1208554,
  },
  4: {
    lat: 48.1540445,
    lng: 17.1300928,
  },
  5: {
    lat: 48.1447136,
    lng: 17.1480435,
  },
  6: {
    lat: 48.1549942,
    lng: 17.1521074,
  },
  7: {
    lat: 48.1551064,
    lng: 17.1290782,
  },
  8: {
    lat: 48.1462355,
    lng: 17.1628985,
  },
  9: {
    lat: 48.1463424,
    lng: 17.1395181,
  },
  10: {
    lat: 48.1472853,
    lng: 17.1426952,
  },
  11: {
    lat: 48.1489042,
    lng: 17.1404431,
  },
  12: {
    lat: 48.1449844,
    lng: 17.1239624,
  },
  13: {
    lat: 48.1460091,
    lng: 17.1202596,
  },
  14: {
    lat: 48.1466365,
    lng: 17.1179653,
  },
  15: {
    lat: 48.1510568,
    lng: 17.1327604,
  },
  16: {
    lat: 48.1519066,
    lng: 17.1361317,
  },
  17: {
    lat: 48.1526982,
    lng: 17.1338049,
  },
  18: {
    lat: 48.1528869,
    lng: 17.1428423,
  },
  19: {
    lat: 48.1488027,
    lng: 17.1620333,
  },
};

// sport
categories[8] = {
  0: {
    lat: 48.1499635,
    lng: 17.1425054,
  },
  1: {
    lat: 48.1500424,
    lng: 17.1197757,
  },
  2: {
    lat: 48.1533339,
    lng: 17.1208929,
  },
  3: {
    lat: 48.1502694,
    lng: 17.1434596,
  },
  4: {
    lat: 48.1558582,
    lng: 17.1505139,
  },
  5: {
    lat: 48.1526417,
    lng: 17.1564071,
  },
  6: {
    lat: 48.1523709,
    lng: 17.1535506,
  },
};

function initMap() {
  if ($('#map_about').length) {
    _lat = 48.142654;
    _lng = 17.1406926;

    map = new google.maps.Map(document.getElementById('map_about'), {
      zoom: 15,
      center: {
        lat: _lat,
        lng: _lng,
      },
      styles: mapStyles,
    });

    _pos = {
      lat: _lat,
      lng: _lng,
    };

    var icon = new google.maps.MarkerImage('/img/map_ico/map_pin.svg', null, null, null, new google.maps.Size(36, 34));

    var marker = new google.maps.Marker({
      position: _pos,
      map,
      icon,
    });

    for (i in categories) {
      if (parseInt(i) > -1) {
        for (j in categories[i]) {
          if (parseInt(j) > -1) {
            _data = categories[i][j];
            _cat = parseInt(i);

            var icon = new google.maps.MarkerImage(`/img/map_ico/map_ico_${_cat}.svg`, null, null, null, new google.maps.Size(26, 26));

            var marker = new google.maps.Marker({
              position: _data,
              map,
              icon,
              cat: parseInt(i),
            });

            markersRef.push(marker);
          }
        }
      }
    }
  }
}

function setMapOnAll(map, cat) {
  for (let i = 0; i < markersRef.length; i++) {
    if (markersRef[i].cat == cat) {
      markersRef[i].setMap(map);
    }
  }
}
