const LANDSCAPE = 'landscape';
const PORTRAIT = 'portrait';
let oldOrientation = '';

function controlVideo(orientation, vMobile, vDesktop) {
  // eslint-disable-next-line default-case
  switch (orientation) {
    case LANDSCAPE:
      vDesktop.play();
      vMobile.pause();
      break;
    case PORTRAIT:
      vDesktop.pause();
      vMobile.play();
      break;
  }
}

function handleScreenSizeChange(vMobile, vDesktop) {
  const orientationL = window.matchMedia(`(orientation: ${LANDSCAPE})`);
  const orientation = orientationL.matches ? LANDSCAPE : PORTRAIT;
  if (oldOrientation === '') {
    controlVideo(orientation, vMobile, vDesktop);
    oldOrientation = orientation;
  }

  if (orientation !== oldOrientation) {
    controlVideo(orientation, vMobile, vDesktop);
    oldOrientation = orientation;
  }
}

export default function init($) {
  const vMobile = document.getElementById('video_mobile');
  const vDesktop = document.getElementById('video_desktop');

  if (vMobile && vDesktop) {
    handleScreenSizeChange(vMobile, vDesktop);
    vMobile.muted = true;
    vDesktop.muted = true;

    $(window)
      .on('resize', () => {
        handleScreenSizeChange(vMobile, vDesktop);
      });
  }
}
