export default (config) => {
  // CONFIG EXAMPLE
  // {
  //     main: {
  //         title: 'Example',
  //             description: 'Example',
  //             moreLink: {
  //             text: 'Example',
  //                 link: 'Example'
  //         }
  //     },
  //     cookiesTypes: [
  //         {
  //             event: null,
  //             title: 'Example',
  //             description: 'Example',
  //             disabled: true
  //         },
  //     ],
  //
  // };

  const init = () => {
    if (checkCookiesWindowAccepted() == false) {
      toHtml();
      toggleCookieWindow();
      registerListeners();
    } else {
      fireGTMEvents();
    }
  };

  const fireGTMEvents = () => {
    config.cookiesTypes.forEach((cookieType) => {
      const cookie = getCookie(cookieType.event);
      if (cookie == 'true') {
        dataLayer.push({ event: cookieType.event });
      }
    });
  };

  const acceptAll = () => {
    config.cookiesTypes.forEach((cookieType) => {
      setCookie(cookieType.event, true);
    });
    setCookiesWindowAccepted();
    fireGTMEvents();
  };

  const checkCookiesWindowAccepted = () => {
    const cookie = getCookie('cookieWindowAccepted');
    if (cookie !== 'true') {
      return false;
    }
    return true;
  };

  const setCookiesWindowAccepted = () => {
    setCookie('cookieWindowAccepted', true);
    toggleCookieWindow();
  };

  const toggleCookieWindow = () => {
    const cookieDisclaimer = document.getElementById('cookies-disclaimer');
    if (cookieDisclaimer.style.display === 'block') {
      cookieDisclaimer.style.display = 'none';
    } else {
      cookieDisclaimer.style.display = 'block';
    }
  };

  const showAdvanceSettings = () => {
    const advanceSettings = document.getElementById('cookies-disclaimer-advanced-settings');
    const advanceSettingsButton = document.getElementById('cookies-disclaimer-advanced-settings-button');
    const closeButton = document.getElementById('cookies-disclaimer-close-button');

    advanceSettings.style.display = 'block';
    advanceSettingsButton.style.display = 'none';
    closeButton.style.display = 'inline';
  };

  const toggleCookie = (cookieName) => {
    const cookie = getCookie(cookieName);
    if (cookie == 'true') {
      setCookie(cookieName, false);
    } else {
      setCookie(cookieName, true);
    }

    fireGTMEvents();
  };

  const setCookie = (name, value) => {
    const date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    const expires = `; expires=${date.toUTCString()}`;

    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  };

  const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const toHtml = () => {
    const content = `<h2 class="title">${config.main.title}</h2>
                        <div class="description">
                           ${config.main.description}
                        </div>

                        <a class="more-link" href="${config.main.moreLink.link}" target="_blank">${config.main.moreLink.text}</a>

                        <div id="cookies-disclaimer-advanced-settings" style="display: none">
                                ${getItemsHtml()}
                        </div>

                        <div class="acceptance">
                            <a id="cookies-disclaimer-advanced-settings-button" class="cookies-btn ghost-button" role="button" >${config.main.settings}</a>
                            <a id="cookies-disclaimer-close-button" class="cookies-btn ghost-button" style="display: none" role="button">${config.main.save}</a>
                            <a id="cookies-disclaimer-accept-all" class="cookies-btn acceptance-all" role="button">${config.main.agree}</a>
                        </div>`;

    const container = document.createElement('div');
    container.id = 'cookies-disclaimer';
    container.innerHTML = content;
    document.body.appendChild(container);
  };

  const registerListeners = () => {
    // let showAdvanceSettings = showAdvanceSettings.bind({});
    // let setCookiesWindowAccepted = setCookiesWindowAccepted.bind({});
    // let acceptAll = acceptAll.bind({});

    document.addEventListener('click', (evnt) => {
      if (evnt.target.id === 'cookies-disclaimer-advanced-settings-button') {
        showAdvanceSettings();
      }

      if (evnt.target.id === 'cookies-disclaimer-close-button') {
        setCookiesWindowAccepted();
      }

      if (evnt.target.id === 'cookies-disclaimer-accept-all') {
        acceptAll();
      }

      if (evnt.target.classList.contains('cookieMarketingEvent')) {
        toggleCookie(evnt.target.dataset.cookie);
      }
    });
  };

  const getItemsHtml = () => {
    let html = '';
    config.cookiesTypes.forEach((value) => {
      html += `<div class="item">
                        <div class="column switcher">
                            <label class="switch">
                                <input class="cookieMarketingEvent" data-cookie="${value.event}"
                                       type="checkbox" ${getCookie(value.event) == 'true' ? 'checked' : ''} ${value.disabled == true ? 'disabled' : ''}>
                                    <span class="slider round"></span>
                            </label>
                        </div>
                        <div class="column">
                            <h4>${value.title}</h4>
                            <p>${value.description}</p>
                        </div>
                    </div>`;
    });

    return html;
  };

  return {
    init,
  };
};
