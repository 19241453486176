import $ from 'jquery';
import { indexOf } from 'lodash/array';

export default () => {
  const bigGalleryCarousel = $('.gallery-big-carousel');
  const smallGalleryCarousel = $('.gallery-small-carousel');

  bigGalleryCarousel
    .slick({
      dots: false,
      infinite: false,
      speed: 700,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      arrows: true,
      prevArrow: $('#gallery-big-carousel-prev'),
      nextArrow: $('#gallery-big-carousel-next'),
      asNavFor: smallGalleryCarousel,
    });

  smallGalleryCarousel
    .slick({
      dots: false,
      infinite: false,
      speed: 700,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      arrows: true,
      prevArrow: $('#gallery-small-carousel-prev'),
      nextArrow: $('#gallery-small-carousel-next'),
      asNavFor: bigGalleryCarousel,
    });

  const smallGalleryCarouselItems = smallGalleryCarousel.find('.slick-slide');

  smallGalleryCarouselItems.on('click', function () {
    const index = indexOf(smallGalleryCarouselItems, this);
    bigGalleryCarousel
      .slick('slickGoTo', index, false);
  });
};
