import './bootstrap';
import 'slick-carousel';
import * as bootstrap from 'bootstrap';
import 'bootstrap-select';
import svgCircles from './svgCircles';
import bigGallery from './bigGallery';
import gtagTrackContactForm from './trackContactForm';
import trackContactFormSuccess from './trackContactFormSuccess';
import '../../public/js/maps';
import cookiesAlertWindow from './cookiesAlert';
import playAppropriateVideoBasedOnOrientation from './videoPlayback';

window.cookiesAlert = cookiesAlertWindow;
window.bootstrap = bootstrap;

const $ = require('jquery');

window.$ = $;

playAppropriateVideoBasedOnOrientation($);

require('lightbox2');

trackContactFormSuccess();
bigGallery();
svgCircles();
gtagTrackContactForm()
  .init();

function handleScrollTop() {
  const scrollTop = $(window)
    .scrollTop();
  const header = $('header');

  if (scrollTop > 90) {
    header.addClass('scroll');
  } else {
    header.removeClass('scroll');
  }
}

function gaEvent(_ga, ev, val) {
  try {
    if (gtag) {
      gtag('event', 'Contact', {
        event_category: ev,
        event_label: val,
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function trackInterest(form, flat) {
  let eventData = {};

  if (form === 'contactForm') {
    eventData = { interestForm: form };

    gaEvent('', 'ContactForm', 'contact');
  } else {
    eventData = {
      interestForm: form,
      interestFlat: flat,
    };

    gaEvent('', 'InterestForm', flat);
  }

  fbq('trackCustom', 'Interest', eventData);
}

function trackContact() {
  gaEvent('', 'Contact', 'contact');
  fbq('track', 'Contact');
}

function trackInterestForm(flat) {
  trackInterest('interestForm', flat);
}

function trackContactForm() {
  trackInterest('contactForm', 0);
}

// circles.forEach((circle) => {
//   const inner = circle.querySelector('circle');
//   const radius = inner.r.baseVal.value;
//   const circumference = radius * 2 * Math.PI;
//   const offset = circumference;
//
//   inner.style.strokeDasharray = `${circumference} ${circumference}`;
//   inner.style.strokeDashoffset = `${circumference}`;
//
//   inner.style.strokeDashoffset = offset;
// });
$(() => {
  $('.developer-carousel')
    .slick({
      dots: false,
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      arrows: true,
      prevArrow: $('#developer-carousel-prev'),
      nextArrow: $('#developer-carousel-next'),
    });

  $('.list-type-carousel')
    .slick({
      dots: false,
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
      arrows: true,
      prevArrow: $('#list-type-carousel-prev'),
      nextArrow: $('#list-type-carousel-next'),
    });

  // eslint-disable-next-line no-undef
  Livewire.on('flatListFiltered', () => {
    $('.list-type-carousel')
      .slick({
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 1,
        centerMode: false,
        variableWidth: true,
        arrows: true,
        prevArrow: $('#list-type-carousel-prev'),
        nextArrow: $('#list-type-carousel-next'),
      });
  });

  const slickDetail = $('.detail-carousel');

  // toto musi byt pred inicializaciou carouselu lebo ten je nastaveny na infinite tak si porobi
  // niekolko dalsich apotom nesedi pocet
  if (slickDetail.length) {
    const els = slickDetail.find('.carousel-item');

    let count = els.length;
    count = (count < 10) ? `0${count}` : count;

    $('#detail-carousel-slides')
      .find('.total')
      .html(count);
  }

  slickDetail.slick({
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: false,
    arrows: true,
    prevArrow: $('#detail-carousel-prev'),
    nextArrow: $('#detail-carousel-next'),
  });

  slickDetail.on('init reInit afterChange', (event, slick, currentSlide) => {
    // currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    let i = (currentSlide || 0) + 1;

    i = (i < 10) ? `0${i}` : i;

    $('#detail-carousel-slides')
      .find('.actual')
      .html(i);
  });

  $('.flat-list-filter-opener')
    .on('click', function () {
      const el = $(this);
      el.toggleClass('show');

      const isOpened = $('.flat-list-filter-opener.show');
      const filterHolder = $('.flat-list-filter-holder');

      if (isOpened.length) {
        filterHolder.addClass('show');
      } else {
        filterHolder.removeClass('show');
      }
    });

  $('.map-filter')
    .on('click', 'a', function (e) {
      e.preventDefault();

      const el = $(this);

      el.toggleClass('active');

      const filterCategory = el.attr('data-filter-category');

      if (!el.hasClass('active')) {
        setMapOnAll(null, filterCategory);
      } else {
        setMapOnAll(map, filterCategory);
      }
    });

  $('.map-filter-opener')
    .on('click', function () {
      const el = $(this);
      el.toggleClass('show');

      const elP = $('.map-filter');

      if (el.hasClass('show')) {
        elP.addClass('show');
      } else {
        elP.removeClass('show');
      }
    });

  $('.trackContactFB')
    .on('click', () => {
      trackContact();
    });

  $('.submitContactForm')
    .on('click', () => {
      trackContactForm();
    });

  $('.submitInterestForm')
    .on('click', function (e) {
      const el = $(this);
      const flat = el.attr('data-flat');

      gtag('event', 'Contact', {
        event_category: 'Interest_form',
        event_label: flat,
      });

      trackInterestForm(flat);

      e.preventDefault();
    });

  $(() => {
    $('.selectpicker')
      .selectpicker();
    handleScrollTop();
  })
    .on('scroll', () => {
      handleScrollTop();
    });

  const animatedInput = document.querySelectorAll('.animatedLabel');

  animatedInput.forEach((input) => {
    input.addEventListener('change', (event) => {
      const input = event.srcElement;
      const parent = input.parentElement;
      const label = parent.querySelector('label');
      const { value } = input;

      if (value) {
        label.classList.add('noAnimation');
      } else {
        label.classList.remove('noAnimation');
      }
    });
  });

  const floorSvg = $('.detail-floor-plan-holder')
    .find('svg');

  if (floorSvg.length) {
    $('<div id="bubble"><span id="bubble_span_title"></span><span id="bubble_span_area"></span></div>')
      .insertAfter('.inner');

    floorSvg
      .on('mouseover', 'g[data-title]', function () {
        const el = $(this);

        const title = el.attr('data-title');
        const area = el.attr('data-area');

        $('#bubble')
          .show();

        $('#bubble_span_title')
          .html(title);
        $('#bubble_span_area')
          .html(`${area} m<sup>2</sup>`);
      })
      .on('mousemove', 'g[data-title]', (e) => {
        const mouseX = e.pageX;
        const mouseY = e.pageY;

        const bubble = $('#bubble');

        bubble.show();

        const bubbleWidth = bubble.width();
        const bubbleHeight = bubble.height();

        const bubbleTop = mouseY - bubbleHeight;
        const bubbleLeft = mouseX - bubbleWidth - 18 /* arrow */ - 40;

        bubble.css({
          top: `${bubbleTop}px`,
          left: `${bubbleLeft}px`,
        });
      })
      .on('mouseout', 'g[data-title]', () => {
        $('#bubble')
          .hide();
      });
  }

  const locationSvg = $('.detail-floor-location-outside-holder')
    .find('svg');

  if (locationSvg.length) {
    $('<div id="location_bubble"><span></span></div>')
      .insertAfter('.inner');

    locationSvg
      .on('mouseover', 'a', function () {
        const el = $(this);

        const state = el.attr('data-state');

        const locationBubble = $('#location_bubble');

        locationBubble.show();

        switch (state) {
          case 'available':
            locationBubble.find('span')
              .html('Voľný');
            break;
          case 'sold':
            locationBubble.find('span')
              .html('Predaný');
            break;
          case 'reserved':
            locationBubble.find('span')
              .html('Rezervovaný');
            break;
        }
      })
      .on('mousemove', 'a', (e) => {
        const mouseX = e.pageX;
        const mouseY = e.pageY;

        const bubble = $('#location_bubble');

        bubble.show();

        const bubbleWidth = bubble.width();
        const bubbleHeight = bubble.height();

        const bubbleTop = mouseY - bubbleHeight;
        const bubbleLeft = mouseX - bubbleWidth - 18 /* arrow */ - 24;

        bubble.css({
          top: `${bubbleTop}px`,
          left: `${bubbleLeft}px`,
        });
      })
      .on('mouseout', 'a', () => {
        $('#location_bubble')
          .hide();
      });
  }
  $('a[href^="tel:"]')
    .on('click', function (element) {
      const $me = $(this);
      gtag('event', 'Contact', {
        event_category: 'phone_number',
        event_label: $me.attr('href'),
      });
    });

  $('a[href^="mailto:"]')
    .on('click', function (element) {
      const $me = $(this);
      gtag('event', 'Contact', {
        event_category: 'email',
        event_label: $me.attr('href'),
      });
    });

  $('a[href^="#detail-contact"]')
    .on('click', (element) => {
      gtag('event', 'Contact', {
        event_category: 'Interest',
        event_label: $('body h1')
          .text(),
      });
    });
});

function setCookie(cName, cValue, expDays) {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${expirationDate.toUTCString()}`;
  document.cookie = `${cName}=${cValue};${expires};path=/`;
}

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

window.visualizationFlats = (route, flatId = null, flatType = null) => {
  const domFlats = document.querySelectorAll('#visualization-flats a g');

  fetch(route)
    .then((response) => response.json())
    .then((responseData) => {
      domFlats.forEach((svgFlat) => {
        let svgFloor;
        let svgFlatType;
        // eslint-disable-next-line prefer-const
        [svgFlatType, svgFloor] = svgFlat.id.split('_');

        // floor +2 becouse wrong svg maping
        svgFloor = Number(svgFloor) + 2;

        // Search for info in json
        const jsonType = Object.entries(responseData)
          .find((el) => el[0] === svgFlatType);
        const jsonFlat = jsonType[1].find((el) => el.floor === svgFloor);

        // Set results
        svgFlat.parentElement.setAttribute('data-state', jsonFlat.state);
        svgFlat.parentElement.setAttribute('href', `/zoznam-bytov/byt/${jsonFlat.id}`);

        if (flatId !== null && jsonFlat.id === flatId) {
          svgFlat.parentElement.classList.add('active');
        }

        if (flatType !== null && jsonFlat.flat_type.name === flatType) {
          svgFlat.parentElement.classList.add('active');
        }
      });
    })
    .catch();
};
window.addEventListener('pageshow', (evt) => {
  if (evt.persisted) {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }
}, false);
