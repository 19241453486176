export default () => ({
  init(formId) {
    const id = formId || 'main-contact-form';
    const form = document.getElementById(id);
    if (form) {
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        gtag('event', 'Contact', {
          event_category: 'Interest_form',
          event_label: 'Contact page',
          event_callback() {
            form.submit();
          },
        });
      });
    }
  },
});
