export default () => {
  const progressRings = document.querySelectorAll('.progress-ring');
  const animationDuration = 4000;

  function contDown({
    info,
    max,
    min,
  }, i) {
    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      info.innerHTML = max - i;
    }, (animationDuration / (max - min)) * i);
  }

  function animateNumbers(circle) {
    const cycles = circle.max - circle.min;
    // eslint-disable-next-line no-plusplus
    let iteration = 0;
    for (iteration; cycles >= iteration; iteration += 1) {
      contDown(circle, iteration);
    }
  }

  const rings = Array.from(progressRings)
    .map((ring) => ({
      ring,
      circle: ring.querySelector('.progress-ring__circle'),
      max: parseInt(ring.getAttribute('data-max'), 10),
      min: parseInt(ring.getAttribute('data-free'), 10),
      info: ring.parentNode.querySelector('.progress-ring__free'),
      animationDuration,
    }));

  rings.forEach((ring) => {
    // eslint-disable-next-line no-param-reassign
    ring.circle.style.strokeDashoffset = `${364 - (364 * (ring.min / ring.max))}px`;
    animateNumbers(ring);
  });
};
